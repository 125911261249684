body, html {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif;
  background-color: white;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-right: 40%;
  padding-left: 2%;
}

.container {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  text-align: left;
  margin-left: 40px;
}

.Img {
  margin-bottom: 20px;
}

.Img img {
  width: 60px;
  height: 60px;
}

.header {
  font-family: 'DM Sans', sans-serif;
  font-weight: bold;
  font-size: 72px;
  line-height: .85;
  margin: 20px 0;
}

.about {
  font-weight: 300;
  font-size: 24px;
  width: 80%;
  max-width: 600px;
  margin: 0 0 40px 0;
}

.links {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 20px;
}

.links li {
  padding: 15px 30px;
  background: #f4f4f4;
  color: #333;
}

.links a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s ease;
  font-weight: bold;
  font-size: 20px;  /* Default size for large screens */
}

.links a:hover {
  color: #007bff;
}

/* Media Queries for Mobile Responsiveness */
@media screen and (max-width: 768px) {
  body {
    padding-right: 0; /* Remove padding for tablet */
}

  .Img img {
      width: 40px;
      height: 40px;
  }
  
  .header {
      font-size: 55px;
  }

  .about {
      width: 60%;
      font-size: 18px;
  }

  .links {
      flex-direction: column;
  }

  .links a {
    font-size: 16px;  /* Smaller size for tablets */
}

  .links li {
      max-width: 200px;
  }
}

@media screen and (max-width: 480px) {
  html, body {
    overflow-x: hidden;
    overflow-y: hidden; /* Prevent horizontal scrolling */
  }

  body {
    margin: 0;
    padding: 0;
  }

  .container {
    margin: 0 auto; /* Center the content */
    padding: 0 10px; /* Ensure some padding for readability */
    padding-bottom: 20px;
  }

  .Img img {
      width: 35px;
      height: 35px;
  }
  
  .header {
      font-size: 55px;
  }

  .about {
      font-size: 20px;
      width: 80%;
  }

  .links li {
      width: 60%;
  }

  .links a {
      font-size: 20px;
  }

  .container {
      margin-left: 10px; /* Reduced margin for mobile */
  }
}